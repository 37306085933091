import _isEmpty from 'underscore/cjs/isEmpty.js'

export const usePostcodeStore = defineStore('postcode', {
  state: () => ({
    postcodeArr: []
  }),

  actions: {
    async getPostcodeList () {
      const { geodataSource } = useEnv()

      if (_isEmpty(this.postcodeArr)) {
        this.postcodeArr = await $fetch(geodataSource)
          .then(res => !_isEmpty(res) ? res : [])
      }
    }
  }
})
