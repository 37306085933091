<template>
  <AtomsInput
    v-if="postcodeOnly"
    ref="postcodeRef"
    v-model="postcodeOnlyValue"
    autocomplete="off"
    :wrapper-class="wrapperClass"
    :label="label"
    :name="$attrs.name || 'Postcode'"
    :placeholder="$attrs.placeholder || 'Enter postcode'"
    :model-type="modelType"
    :error-message="errorStatusMessage"
    :is-success="props.isSuccess || status === 'success'"
    :maxlength="$attrs.maxlength || 4"
    :theme="theme"
    :disabled="disabled"
    @update="handleValidaton"
    @keypress="handleNumbers"
  />

  <MoleculesSelectDropdown
    v-else
    ref="postcodeRef"
    v-model="postcodeValue"
    autocomplete="off"
    :wrapper-class="wrapperClass"
    :data-arr="postcodeArr"
    :label="label"
    :name="$attrs.name || 'Postcode'"
    :placeholder="$attrs.placeholder || 'Enter postcode or suburb'"
    :model-type="modelType"
    :data-list-display="dataListDisplay"
    :data-input-display="dataInputDisplay"
    :data-list-limit="dataListLimit"
    :searchable="searchable"
    :error-message="props.errorMessage"
    :is-success="props.isSuccess || status === 'success'"
    :character-delay="characterDelay"
    :maxlength="modelType === 'number' ? 4 : ''"
    :theme="theme"
    :disabled="disabled"
    @blur="handleValidaton"
  />
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'MoleculesPostcode'
})

const postcodeStore = usePostcodeStore()
const { postcodeArr } = storeToRefs(postcodeStore)

const props = defineProps({
  modelValue: {
    type: [Object, Array, String],
    default: () => ({})
  },

  modelType: {
    type: String,
    default: 'text', // number
    validator: value => ['number', 'text'].includes(value)
  },

  searchable: {
    type: Boolean,
    default: true
  },

  // show dropdown list delay time
  characterDelay: {
    type: [String, Number],
    default: 3,
    validator: value => !isNaN(value)
  },

  /**
   * limit list to be displayed
   */
  dataListLimit: {
    type: Number,
    default: 100
  },

  // key used to display data in dropdown
  dataListDisplay: {
    type: [String, Number],
    default: 'name'
  },

  // key used to display data in <input>
  dataInputDisplay: {
    type: [String, Number],
    default: '' // 2
  },

  errorMessage: {
    type: String,
    default: ''
  },

  isSuccess: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'default'
  },

  disabled: {
    type: Boolean,
    default: false
  },

  label: {
    type: String,
    default: 'Postcode'
  },

  wrapperClass: {
    type: [String],
    default: ''
  },

  postcodeOnly: {
    type: Boolean,
    default: false
  }
})

const $attrs = useAttrs()
const emit = defineEmits([
  'validation',
  'update',
  'update:model-value'
])

const postcodeValue = ref()
const postcodeOnlyValue = ref()
const postcodeRef = ref()

const postcodeOnlyStatusValue = ref()
const errorStatusMessage = ref('')

const status = ref()

const handlePostcodeUpdate = postcode => {
  if (postcode.value) {
    /**
     * if postcode.value is an array
     * it emits a reconstructed value to
     * { suburb, state, postcode }
     */
    if (isArray(postcode.value)) {
      emit('update:model-value', {
        suburb: postcode.value[0],
        state: postcode.value[1],
        postcode: postcode.value[2]
      })
      emit('update', {
        suburb: postcode.value[0],
        state: postcode.value[1],
        postcode: postcode.value[2]
      })
    } else {
      postcodeValue.value = [
        postcode.value.suburb,
        postcode.value.state,
        postcode.value.postcode
      ]
    }
  } else {
    emit('update:model-value', '')
    emit('update', '')
  }
}

watch(() => postcodeValue, handlePostcodeUpdate, { deep: true })
watch(() => postcodeOnlyStatusValue, handlePostcodeUpdate, { deep: true })

watch(() => props.errorMessage, value => {
  if (value && props.postcodeOnly) {
    errorStatusMessage.value = 'Please enter valid postcode'
  }
}, { deep: true })

onMounted(async () => {
  await nextTick()

  await postcodeStore.getPostcodeList()

  if (!_isEmpty(props.modelValue) && isObject(props.modelValue)) {
    if (!props.postcodeOnly) {
      postcodeValue.value = [
        props.modelValue.suburb,
        props.modelValue.state,
        props.modelValue.postcode
      ]
    } else {
      postcodeOnlyValue.value = props.modelValue.postcode
    }
  }
})

async function handleValidaton () {
  await wait(100)
  let isSuccess = false

  if (props.postcodeOnly) {
    const isNumber = /^\d+$/.test(postcodeOnlyValue.value)

    const matchedPostcodeObject = postcodeArr.value.find(item => item[2] === postcodeOnlyValue.value)

    const isValidPostcode = !!matchedPostcodeObject
  
    isSuccess = isNumber && isValidPostcode
    postcodeOnlyStatusValue.value = matchedPostcodeObject
    errorStatusMessage.value = !isSuccess ? 'Please enter valid postcode' : ''
  } else {
    const postcodeVal = postcodeRef.value?.selected?.values[0]
    isSuccess = !!(JSON.stringify(postcodeValue.value) === JSON.stringify(postcodeVal) && postcodeValue.value && postcodeVal)
  }

  status.value = (() => {
    if (props.errorMessage) {
      return 'error'
    }

    return isSuccess
      ? 'success'
      : ''
  })()

  emit('validation', {
    isSuccess,
    errorMessage: !isSuccess && (props.postcodeOnly ? 'Please enter valid postcode' : 'Please enter valid postcode or suburb')
  })
}

const handleNumbers = $event => {
  const char = String.fromCharCode($event.keyCode)

  if (/^\d+$/.test(char)) {
    return true
  }

  if (![13, 8].includes($event.keyCode)) {
    $event.preventDefault()
  }
}
</script>
